import { useHints } from '~/utils/client-hints'
import { useRequestInfo } from './useRequestInfo'


/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme(): 'light' | 'dark' {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	return requestInfo.userPrefs.theme ?? hints.theme
}
